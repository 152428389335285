<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="商户名称" prop='supplyShopId'>
          <a-select v-model="params.supplyShopId " placeholder="请选择" style="min-width: 160px">
            <a-select-option v-for='item in shopList' :key='item.shopId' :value='item.shopId'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="到账状态" prop='state'>
          <a-select v-model="params.state" placeholder="请选择" style="min-width: 160px">
            <a-select-option v-for='item in stateOption' :key='item.value' :value='item.value'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属员工" prop='shopStaffId'>
          <a-select v-model="params.shopStaffId" placeholder="请选择" style="min-width: 240px">
            <a-select-option v-for='item in staffList' :key='item.shopStaffId' :value='item.shopStaffId'>{{item.loginName}} {{item.realName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-table :columns="columns" bordered :dataSource="tableData" :rowKey="(record,index) => record.orderNo" :loading="tableLoading" :pagination='pagination' @change="handleTableChange">
      <template slot="name" slot-scope="text,record">
        <div class="goods-info">
          <base-img class="goods-img" :src="record.logo" width="60" height="60" oss-style="60_60" />
          <div>
            <p @click="goShopDetail(record.shopId)" class="goods-name shop-name el2">{{record.name}}</p>
            <span :style="{fontSize: '13px', color: shopAuthStateFormat(record.authState||0, 'color')}">{{shopAuthStateFormat(record.authState||0, 'text')}}</span>
          </div>
        </div>
      </template>
      <template slot="loginName" slot-scope="text,record">
        <div>{{record.realName}}</div>
        <div>{{record.loginName}}</div>
      </template>
      <template slot="incomeAmount" slot-scope="text,record">
        <div>{{record.incomeAmount}}</div>
        <div v-if="record.incomeAmount===0">订单已退款</div>
      </template>
      <template slot="state" slot-scope="text,record">
        {{record.state===3?"已到账":"未到账"}}
      </template>
    </a-table>
  </div>
</template>
<script>
import NP from 'number-precision'
import { capital, staff } from '@/api'
import { onMounted, reactive, toRefs, ref } from '@vue/composition-api'
import { SHOPID, SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
import { shopAuthStates } from '@/utils/constants'
export default {
  name: 'ShopInfo',
  setup (props, { root }) {
    const formRef = ref(null)
    const columns = [
      {
        title: '商户名称',
        width: 200,
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '所属员工',
        dataIndex: 'loginName',
        scopedSlots: { customRender: 'loginName' },
      },
      {
        title: '订单编号',
        dataIndex: 'orderNo',
      },
      {
        title: '收益金额',
        dataIndex: 'incomeAmount',
        scopedSlots: { customRender: 'incomeAmount' },
      },
      {
        title: '到账状态',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      }
    ]
    onMounted(async () => {
      await Promise.all([getStaffList(), getSupplyShopList()])
      getTableData()
    })

    const state = reactive({
      authVisible: false,
      authModalLoading: false,
      tableLoading: false,
      params: {
        shopStaffId: undefined,
        supplyShopId: undefined,
        state: undefined,
      },
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },

      staffList: [],
      shopList: [],
      stateOption: [
        {
          name: '已到账',
          value: 3,
        },
        {
          name: '未到账',
          value: 2,
        },
      ],
    })
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }
    async function getTableData () {
      state.tableLoading = true
      let { code, data, msg, page } = await capital.getSettleRecordPage({
        shopId: getSession(SHOPID),
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.params,
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleSearch () {
      state.pagination.current = 0
      getTableData()
    }
    async function getSupplyShopList () {
      const { data, code } = await capital.getSupplyShopList({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.shopList = data
        if (root.$route.params.shopId) {
          state.params.supplyShopId = root.$route.params.shopId
        }
      }
    }
    function shopAuthStateFormat (state, type) {
      const matching = shopAuthStates.find((x) => x.value === +state)
      if (type === 'text') return matching ? matching.name : ''
      if (type === 'color') return matching ? matching.color : ''
    }
    function handleReset () {
      formRef.value.resetFields()
      if (root.$route.params.shopId) {
        state.params.supplyShopId = root.$route.params.shopId
      }
      handleSearch()
    }
    async function getStaffList () {
      const { data, code } = await staff.getSelectStaffData({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.staffList = data
      }
    }
    function goShopDetail (id) {
      if (id === getSession(SHOPID)) {
        root.$router.push('/shop/info')
      } else {
        root.$router.push('/goods/shop_goods_list/' + id)
      }
    }
    return {
      formRef,
      columns,
      ...toRefs(state),
      handleTableChange,
      handleSearch,
      handleReset,
      getStaffList,
      shopAuthStateFormat,
      goShopDetail,
      getSupplyShopList,
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0px;
}
.page-head {
  .head-item {
    padding: 8px 40px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84px;
    .value {
      font-weight: 500;
      font-size: 24px;
    }
    .label {
      font-size: 14px;
      color: #666;
      line-height: 24px;
    }
    &:last-child {
      border-right: 0;
    }
    .withdrawal-box {
      display: flex;
      align-items: center;
      span {
        margin-right: 32px;
      }
    }
  }
}
.goods-info {
  display: flex;
  align-items: center;
  &.goods {
    cursor: pointer;
  }
  .goods-img {
    flex: none;
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
    &.shop-name {
      color: #0066ff;
      cursor: pointer;
    }
  }
}
</style>
